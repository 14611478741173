(function () {
    app.controller('OrderRoomsSectionCtrl', ['$scope', '$timeout', '$analytics', '$http', '$interval', function ($scope, $timeout, $analytics, $http, $interval) {
        $scope.NAV_MEMBERS = 'Members';
        $scope.NAV_ROOMS = 'Rooms';
        $scope.navItems = [$scope.NAV_MEMBERS, $scope.NAV_ROOMS]
        $scope.currentNav = $scope.NAV_MEMBERS

        $timeout(() => {
            $scope.clonedRooms = $.extend(true, [], $scope.rooms);
            let leaderName = '';
            let sequentialFlag = -1;
            $scope.clonedRooms.forEach(room => {
                if (room.is_leader && !leaderName) {
                    leaderName = room.member_name
                }
            })
            $scope.membersMap = $scope.clonedRooms.reduce((prev, curr) => {
                if (curr.member_name === leaderName) {
                    sequentialFlag += 1;
                    if (sequentialFlag > 0) {
                        curr.is_leader = false;
                        curr.member_name = `${curr.member_name} (${sequentialFlag})`
                    }
                }
                prev[curr.id] = {...curr}
                return prev
            }, {})
            $scope.roomsMap = $scope.clonedRooms.reduce((prev, curr) => {
                if (!prev[curr.room_number] || curr.hotel_confirmation) {
                    prev[curr.room_number] = {
                        confirmationNumber: curr.hotel_confirmation,
                    }
                }
                return prev
            }, {})
        })

        $scope.switchNav = (navItem) => {
            $scope.currentNav = navItem
        }

        $scope.getComposedData = () => {
            return Object.keys($scope.membersMap).map(id => {
                const member = $scope.membersMap[id]
                return {
                    split_order_id: Number(id),
                    room_member_id: Number(id),
                    name: member.member_name,
                    email: member.email,
                    phone_number: member.phone_number,
                    confirmation_number: $scope.roomsMap[member.room_number].confirmationNumber,
                    room_leader: member.room_leader
                }
            })
        }

        $scope.hasDuplicateEmails = () => {
            const encounteredEmails = {};
            for (const id of Object.keys($scope.membersMap)) {
                const member = $scope.membersMap[id];
                if (member.email && encounteredEmails[member.email]) {
                    return true;
                }
                encounteredEmails[member.email] = true;
            }
            return false;
        }

        $scope.update = async () => {
            if ($scope.isSplitOrder && $scope.hasDuplicateEmails()) {
                toastr.error(`There are members who have duplicate emails`);
                return;
            }
            await window.orderModifyApi.updateRoomMemberInfo({
                order_number: $scope.order?.order_number,
                guest_list: $scope.getComposedData()
            })
            window.location.reload()
        }

        $scope.initPhoneNumber = function (item) {
            $('document').ready(function(){
              var idx = item.id;
              var phone_input = document.querySelector("#phone_number_" + idx),
              errorMsg = document.querySelector("#error-msg-" + idx),
              validMsg = document.querySelector("#valid-msg-" + idx);

              var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
              let member_iti = window.intlTelInput(phone_input, {
                initialCountry: "us",
                nationalMode: true,
                utilsScript: "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544"
              });
          
              var reset = function() {
                phone_input.classList.remove("error");
                errorMsg.innerHTML = "";
                errorMsg.classList.add("hidden");
                validMsg.classList.add("hidden");
              };
    
              phone_input.addEventListener('blur', function() {
                reset();
                if (phone_input.value.trim()) {
                  if (member_iti.isValidNumber()) {
                    validMsg.classList.remove("hidden");
                  } else {
                    phone_input.classList.add("error");
                    var errorCode = member_iti.getValidationError();
                    errorMsg.innerHTML = errorMap[errorCode];
                    errorMsg.classList.remove("hidden");
                  }
                }
                $.each($scope.membersMap, function (_, member) {
                    if (member.id == item.id) {
                        member.phone_number = member_iti.getNumber();
                    }
                });
              });
              phone_input.addEventListener('change', reset);
              phone_input.addEventListener('keyup', reset);
            });
          }

        $scope.roomleader = function (item) {
            let leader_room_number = -1;
            $.each($scope.membersMap, function (id, member) {
                if (member.is_leader) {
                    leader_room_number = member.room_number;
                }
            });
            return leader_room_number == item.room_number
        };

        $scope.selectRoomLeader = function (item) {
            $.each($scope.membersMap, function (id, member) {
                if (item.room_number == member.room_number) {
                    member.room_leader = item.email
                }
            });
          };
    }]);
}.call(this))