(function () {
  app.controller("UserDashboardCtrl", [
    "$scope",
    "$timeout",
    "$window",
    function ($scope, $timeout, $window) {
      const EMAIL_PATTERN =
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
      $scope.event_tabs = {};
      $scope.account_tabs = {};
      $scope.current_event = null;
      $scope.current_event_tab = null;
      $scope.current_account_tab = null;
      $scope.current_user = null;
      $scope.event_info = {};
      $scope.event_party = {
        single_order_data: [],
        shared_order_data: [],
        main_order_data: [],
      };
      $scope.event_payment = {};
      $scope.profile = {};
      $scope.emergency_contacts = [];
      $scope.credit_cards = [];
      $scope.transfer_info = {};
      $scope.bill_type = {
        single_order: "Single Order",
        group_order: "Group Order",
      };
      $scope.show_room_members = false;
      $scope.can_save_room_members = false;
      $scope.order_statuses = { open: 0, paid: 1, cancelled: 2 };
      $scope.change_requested = {
        replaced_member_name: "",
      };
      $scope.change_requested_errors = {};
      $scope.user_locale = "en";
      $scope.event_currency = window.event_currency || 'USD';
      $scope.member_iti = {};

      $scope.init = function () {};

      $scope.selectEvent = function (event) {
        $scope.current_event = event;
        $scope.selectEventTab($scope.current_event_tab);
        // update event_currency
        const { currencies } = event
        $scope.event_currency = currencies.filter(currency => currency)[0]
      };

      $scope.selectAccountSettings = function () {
        $scope.current_event = null;
      };

      $scope.selectEventTab = function (tab_name) {
        $scope.current_event_tab = tab_name;
        var opt = {
          event_id: $scope.current_event.id,
          tab: tab_name,
        };

        $.post("/dashboard/get_event_data", opt, function (rs) {
          if (rs.succeed) {
            $timeout(function () {
              switch ($scope.current_event_tab) {
                case $scope.event_tabs.PARTY:
                  $scope.event_party = rs.event_party;
                  $scope.groupSelectedTickets();
                  break;
                case $scope.event_tabs.PAYMENT:
                  $scope.event_payment = rs.event_payment;
                  break;
                default:
                  $scope.event_info = rs.event_info;
                  if ($scope.event_info.show_room_member) {
                    showRoomMembers(
                        $scope.event_info.order_room_members,
                        false,
                        rs.event_info.can_change_guest_name_and_email);
                  }
              }
            });
          } else if(rs.message) {
            bootbox.alert(rs.message);
          }
        });
      };

      $scope.get_room_name = function (list_hotel, order_item_id) {
        for (let i = 0; i < list_hotel.length; i++) {
          if (list_hotel[i].id == order_item_id) {
            return list_hotel[i];
          }
        }
      };

      $scope.get_voucher = function (list_voucher, order_item_id, member_name) {
        for (let i = 0; i < list_voucher.length; i++) {
          if (list_voucher[i]['voucher'] != null && list_voucher[i].order_item_id == order_item_id) {
            return list_voucher[i]['voucher'].filter(voucher_member_name => voucher_member_name[member_name])[0][member_name];
          }
        }
      };

      $scope.calculate_total_price = function (order_price, fee_amount) {
        return parseFloat(order_price) + parseFloat(fee_amount);
      };

      $scope.selectAccountSettingTab = function (tab_name) {
        $scope.current_account_tab = tab_name;
        if (tab_name == 'Profile') {
        }
      };

      $scope.resendInvitation = function (order_number, split_order_id) {
        const data = {
          order_number: order_number,
          split_order_id: split_order_id,
        };
        bootbox.confirm(
          "Are you sure you want to send the remind invitation email?",
          function (rs) {
            if (rs) {
              $.post("/dashboard/remind_order_invitation", data, function (rs) {
                $timeout(function () {
                  if (rs.succeed) {
                    location.href = "/dashboard";
                  } else if(rs.message) {
                    bootbox.alert(rs.message);
                  }
                });
              });
            }
          }
        );
      };

      $scope.transferInvitation = function (split_order_id) {
        var data = {
          split_order_id: split_order_id,
          name: $scope.transfer_info.name,
          email: $scope.transfer_info.email,
        };
        $.post("/dashboard/transfer_bill_execution", data, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              location.href = "/dashboard";
            } else if(rs.message) {
              bootbox.alert(rs.message);
            }
          });
        });
      };

      $scope.viewAdditionalFee = function (
        bill_type,
        item_id,
        type,
        room_member_id
      ) {
        var data = {
          bill_type: bill_type,
          item_id: item_id,
          type: type,
          room_member_id: room_member_id,
        };
        $.get("/dashboard/view_additional_fee", data, function (rs) {
          $timeout(function () {
            if (!rs.succeed) {
              bootbox.alert(rs.message);
            }
          });
        });
      };

      $scope.groupSelectedTickets = function () {
        if (
          $scope.event_party &&
          $scope.event_party.hasOwnProperty("shared_order_data")
        ) {
          Object.keys($scope.event_party.shared_order_data).forEach(
            (order_id) => {
              $scope.event_party.shared_order_data[order_id].forEach(
                (order) => {
                  order.ticket_layout = order.ticket_layout.reduce((r, a) => {
                    var section_name = "";
                    var row_array = [];

                    order.seating_sections.forEach((section) => {
                      if (section.id == a.seating_section_id) {
                        section_name = section.name;
                        order.ticket_layout.forEach((seat) => {
                          if (
                            seat.row == a.row &&
                            seat.seating_section_id == a.seating_section_id
                          ) {
                            row_array.push(seat);
                          }
                        });
                      }
                    });
                    r[section_name] = {
                      ...(r[section_name] || []),
                      [a.row]: row_array,
                    };
                    return r;
                  }, {});
                }
              );
            }
          );
        }
      };

      $scope.openChangeRequested = function (member) {
        $scope.change_requested.replaced_member_name = member.member_name;
        $scope.change_requested_errors = {};
        $(`#modalChangeRequested${member.split_order_id}`).modal("show");
      };

      $scope.changeRequested = function (member) {
        const validate = validateChangeRequested(member);
        if (!validate) return;

        var opt = {
          split_order_id: member.split_order_id,
          replaced_member_name: $scope.change_requested.replaced_member_name,
        };
        $.post("/dashboard/send_change_requested", opt, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              toastr.success(rs.message);
              $scope.selectEventTab($scope.event_tabs.PARTY);
              $(`#modalChangeRequested${member.split_order_id}`).modal("hide");
            } else {
              toastr.error("Request failed");
            }
          });
        });
      };

      $scope.isFormValid = function () {
        $scope.can_save_room_members = validName() && validEmail() && everyRoomHasLeader() && validPhoneNumber();
      };

      $scope.changeName = function () {
        validName();
      };

      $scope.changeEmail = function () {
        validEmail();
      };

      $scope.changePhoneNumber = function () {
        validPhoneNumber();
      };

      $scope.closeGuestInfoModal = function () {
        $("#OrderRoomMembers").modal("hide");
      };

      $scope.saveGuestInfo = function () {
        if (!$scope.can_save_room_members) {
          return;
        }

        var params = {
          room_members: $scope.order_room_members,
          event_id: $scope.current_event.id,
        };
        $.post("/dashboard/save_room_member", params, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              toastr.success("Update success");
              $scope.selectEventTab($scope.event_tabs.PARTY);
              $("#OrderRoomMembers").modal("hide");
            } else {
              toastr.error("Update failed");
            }
          });
        });
      };

      $scope.getRoomMember = function (order, roomMember) {
        var params = { order_id: order.id, room_member_id: roomMember.id };
        $.get("/dashboard/order_room_member", params, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              showRoomMembers(
                [rs.room_member],
                true,
                rs.can_change_guest_name_and_email
              );
            } else if(rs.message) {
              bootbox.alert(rs.message);
            }
          });
        });
      };

      $scope.guestRoom = function (roomMembers) {
        return roomMembers.some(function(member) {
            return member.mine == true;
        });
      };

      $scope.selectRoomLeader = function (member) {
        $scope.order_room_members[member.room_number].forEach(room_member => {
          room_member.room_leader = member.id
        });
        $scope.isFormValid();
      };

      $scope.roomCount = function () {
        return Object.values($scope.order_room_members).reduce((total, currentArray) => total + currentArray.length, 0);
      }

      $scope.cancelOrder = function (order) {
        var params = { order_id: order.id };
        $.get("/dashboard/cancel_order", params, function (rs) {
          $timeout(function () {
            if (rs.message) {
              toastr.error(rs.message);
            }
          });
        });
      };

      // ============= functionality =================
      function showRoomMembers(
        order_room_members = null,
        is_showing = false,
        can_change_guest_name_and_email = false
      ) {
        $scope.can_change_guest_name_and_email =
          can_change_guest_name_and_email;
        var order_room_members = order_room_members;
        if (order_room_members.length) {
          var show_room_members = order_room_members.some(
            (member) =>
              member.member_name.includes("Guest") ||
              member.email.includes("TBD") ||
              !member.phone_number
          );
          $scope.show_room_members = is_showing || show_room_members;
          if ($scope.show_room_members) {
            $scope.order_room_members = getRoomMembers(order_room_members);
            $("#OrderRoomMembers").modal("show");
          }
        }
      }

      function getRoomMembers(order_room_members) {
        var member_number = 1;
        var leader_room_number = -1;
        var leader_room_id = -1;
        order_room_members.forEach((item) => {
          if (item.email == $scope.current_user.email) {
            leader_room_number = item.room_number;
            leader_room_id = item.id
          }
        });
        var result = order_room_members.map(item => {
          const new_item = {};
          const room_members_count = order_room_members.filter((member) => member.room_number == item.room_number).length
          new_item.id = item.id;
          new_item.member_number = member_number;
          new_item.room_number = item.room_number;
          new_item.member_name = item.member_name.includes("Guest")
            ? ""
            : item.member_name;
          new_item.email = item.email.includes("TBD") ? "" : item.email;
          new_item.phone_number = item.phone_number
          new_item.mine = item.email == $scope.current_user.email;
          if (leader_room_number == item.room_number) {
            new_item.room_leader = leader_room_id;
          }else if (room_members_count == 1) {
            new_item.room_leader = item.id
          }else{
            new_item.room_leader = null
          }
          member_number += 1;
          return new_item;
        });
        return groupBy(result, "room_number");
      }

      function groupBy(arr, key) {
        return arr.reduce(
          (acc, item) => (
            (acc[item[key]] = [...(acc[item[key]] || []), item]), acc
          ),
          {}
        );
      }

      function validName() {
        var valid_room_members = true;
        $.each($scope.order_room_members, function (room_number, room_members) {
          valid_room_members = room_members.every((member) => {
            member.invalid_name = false;
            const empty_name = member.member_name === "";
            const valid_name = !member.member_name.includes("Guest");
            member.empty_name = empty_name;
            member.invalid_name = !valid_name;
            return valid_name && !empty_name;
          });
          if (!valid_room_members) {
            return valid_room_members;
          }
        });
        return valid_room_members;
      }

      function validEmail() {
        var result = true;
        $.each($scope.order_room_members, function (_, room_members) {
          result = room_members.every((member) => {
            const res = EMAIL_PATTERN.test(member.email);
            member.invalid_email = !res;
            return res;
          });
          if (!result) {
            return result;
          }
        });
        return result;
      }

      function validPhoneNumber() {
        var result = true;
        $.each($scope.order_room_members, function (_, room_members) {
          result = room_members.every((member) => {
            const valid = member.phone_number == undefined || member.phone_number == null || member.phone_number?.trim() == '';
            member.invalid_phone_number = valid;
            return !valid;
          });
          if (!result) {
            return result;
          }
        });
        return result;
      }

      $scope.initPhoneNumber = function (idx, guest) {
        $('document').ready(function(){
          var phone_input = document.querySelector("#phone_number_" + idx),
          errorMsg = document.querySelector(".error-msg-" + idx),
          validMsg = document.querySelector(".valid-msg-" + idx);

          var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
          let member_iti = window.intlTelInput(phone_input, {
            initialCountry: "us",
            nationalMode: true,
            utilsScript: "/admin-theme/plugins/intl-tel-input/build/js/utils.js?1638200991544"
          });
      
          var reset = function() {
            phone_input.classList.remove("error");
            errorMsg.innerHTML = "";
            errorMsg.classList.add("hidden");
            validMsg.classList.add("hidden");
          };

          phone_input.addEventListener('blur', function() {
            reset();
            if (phone_input.value.trim()) {
              if (member_iti.isValidNumber()) {
                validMsg.classList.remove("hidden");
              } else {
                phone_input.classList.add("error");
                var errorCode = member_iti.getValidationError();
                errorMsg.innerHTML = errorMap[errorCode];
                errorMsg.classList.remove("hidden");
              }
            }
            $.each($scope.order_room_members, function (_, room_members) {
              room_members.forEach((member) => {
                if (member.id == guest.id) {
                  member.phone_number = member_iti.getNumber();
                }
              });

            });
          });
          phone_input.addEventListener('change', reset);
          phone_input.addEventListener('keyup', reset);
        });
      }

      function everyRoomHasLeader() {
        var result = true;
        $.each($scope.order_room_members, function (_, room_members) {
          var room_leader = false;
          result = room_members.every((member) => {
            if (member.room_leader) {
              room_leader = true;
            }
            return room_leader;
          });
          if (!result) {
            return result;
          }
        });
        return $scope.roomCount() != 1 ? result : true;
      }
      
      function validateChangeRequested(member) {
        const { replaced_member_name } = $scope.change_requested;
        const valid_name = replaced_member_name.trim().length > 0;
        const like_previous_info = member.member_name === replaced_member_name;

        if (valid_name && !like_previous_info) {
          $scope.change_requested_errors = {};
          return true;
        }

        if (like_previous_info) {
          $scope.change_requested_errors.like_previous_info =
            "Name same as previous information";
        } else {
          delete $scope.change_requested_errors.like_previous_info;
        }

        if (!valid_name) {
          $scope.change_requested_errors.replaced_member_name =
            "Name cannot be blank";
        } else {
          delete $scope.change_requested_errors.replaced_member_name;
        }
        return false;
      }
    },
  ]);
}.call(this));
