// Sentry

import * as Sentry from "@sentry/browser";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

window.Sentry = Sentry;

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    debug: false,
    integrations: [
      new CaptureConsoleIntegration({
        levels: ["error"],
      }),
    ],
  });
}

// Rails stuff
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

// Jquery
import jquery from "jquery";

window.$ = window.jquery = jquery;
window.jQuery = window.jquery = jquery;

// Common Lib
require("packs/libs/jquery-ui");
require("packs/libs/jquery-ui.multidatespicker");
require("moment");
require("bs-rating");
import "bootstrap";
import "select2";
require("bootstrap-timepicker");
require("packs/vendor/ckeditor.min.js");

// Required for authorize.net iframe communication
import { Bellhop } from "bellhop-iframe";
global.Bellhop = Bellhop;

// Amplitude
import amplitude from "amplitude-js";
global.AmplitudeModule = amplitude;

global.Papa = require("papaparse");

// AngularJs
global.bootbox = require("bootbox");
global.toastr = require("toastr");
global.angular = require("angular");
require("packs/base/init");
require("packs/base/angular_app");
require("packs/base/angular_utils");
require("angular-route");
require("angular-nvd3");
require("angular-sanitize");

global.app = angular.module("app");
require("packs/pages/do_surveys_ctrl");
require("packs/pages/bookings_ctrl");
require("packs/pages/hotelbeds_bookings_ctrl");
require("packs/pages/landing_ctrl");
require("packs/pages/invitations_ctrl");
require("packs/pages/user_dashboard_ctrl");
require("packs/pages/credit_card_ctrl");
require("packs/pages/pay_installment_ctrl");
require("packs/libs/owl.carousel.min");
require("packs/pages/admin/seating_section_layout_map_ctrl");
require("packs/pages/admin/evidence_api");
require("packs/pages/admin/comms_platform_api");
require("packs/pages/admin/order_modify_api");
require("packs/pages/admin/events_edit_representment_ctrl");
require("packs/pages/admin/orders_order_show_ctrl");
require("packs/pages/admin/order_modify_rooms_ctrl");
require("packs/pages/admin/order_modify_addons_ctrl");
require("packs/pages/admin/order_rooms_section_ctrl");
require("packs/pages/admin/custom_payment_plan_ctrl");
window.ClipboardCopy = require("packs/pages/copy").default;
