window.orderModifyApi = {
    getSupportAddons({order_number = ''}) {
        return window.ajaxUtils.get(`/orders/new_addons?order_number=${order_number}`);
    },
    getAddonDetails({order_number = '', addon_id = ''}) {
        return window.ajaxUtils.get(`/orders/purchased_addon?order_number=${order_number}&addon_id=${addon_id}`);
    },
    // creat/update/delete addon
    updateAddon({order_number = '', addon_id = '', split_orders = [], quantity = 0, refund = false, discount_amount, discount_reason_code, fee, fee_reason_code}) {
        return window.ajaxUtils.post(`/orders/update_addon`, {
            data: {
                order_number,
                addon_id,
                split_orders,
                quantity,
                refund,
                discount_amount,
                discount_reason_code,
                fee,
                fee_reason_code
            }
        });
    },

    /**
     * Get order hotels
     * Route: /orders/#{order_number}/hotels?occupancy=#{occupancy}
     * Docs: https://docs.google.com/document/d/1WhyiqOv4odfe9GNGWfvij9vb1GhPMAezs3bamR5Rp_8/edit#heading=h.97lgyj8mcb0c
     */
    getOrderHotels({order_number = '', occupancy = 0}) {
        return window.ajaxUtils.get(`/orders/${order_number}/hotels?occupancy=${occupancy}`);
    },

    /**
     * Get order rooms
     * Route: /orders/#{order_number}/hotels/#{product_id}?occupancy=#{occupancy}
     * Docs: https://docs.google.com/document/d/1WhyiqOv4odfe9GNGWfvij9vb1GhPMAezs3bamR5Rp_8/edit#heading=h.ynpyktzeqes5
     */
    getOrderRooms({order_number = '', product_id = '', occupancy = 0}) {
        return window.ajaxUtils.get(`/orders/${order_number}/hotels/${product_id}?occupancy=${occupancy}`)
    },

    /**
     * Get order rooms members
     * Route: /orders/#{order_number}/room_members
     * Docs: https://docs.google.com/document/d/1WhyiqOv4odfe9GNGWfvij9vb1GhPMAezs3bamR5Rp_8/edit#heading=h.bzwpd0atolt2
     */
    getOrderRoomMembers({order_number = ''}, global = true) {
        return window.ajaxUtils.get(`/orders/${order_number}/room_members`, {
            global
        })
    },

    /**
     * Update order rooms
     * Route: /orders/#{order_number}/update_rooms
     * Docs: https://docs.google.com/document/d/1WhyiqOv4odfe9GNGWfvij9vb1GhPMAezs3bamR5Rp_8/edit#heading=h.4y8qo4pkhdoi
     */
    updateOrderRooms({
                         order_number = '',
                         ...options
                     }) {
        return window.ajaxUtils.post(`/orders/${order_number}/update_room_members`, {
            contentType: 'application/json',
            data: JSON.stringify(options)
        })
    },

    /**
     * Update rooms member info
     * Route: /orders/update_room_member_info
     */
    updateRoomMemberInfo({
                             order_number = '',
                             guest_list = []
                         }) {
        return window.ajaxUtils.post('/orders/update_room_member_info', {
            contentType: 'application/json',
            data: JSON.stringify({
                order_number,
                guest_list
            })
        })
    }
};