const HotelBeds = {
  event_store_hb: {
    HOTELBEDS_AVAILABILITY_RESPONSE: 'hotelbeds_availability_response',
    HOTELBEDS_CUSTOMER_HOTEL_DATA_ON_RETURN: 'hotelbeds_customer_hotel_data_on_return',
  },

  updateHotelBedsHotelPrices: ($scope, retry) => {
     // Checking min and max prices for all hotels within this trip date

      // if no default selected, do not check hotelbeds 
      if ($scope.customer_packages.default == undefined) {
        return
      }

      // Get all hotels_codes from customer_packages      
      const filteredHotelBedsCodes = $scope.customer_packages.default.filter((p) => p.hotel_beds_hotel_code)
      const hotelBedsCodes = filteredHotelBedsCodes.map((p) => p.hotel_beds_hotel_code);
      const packages = filteredHotelBedsCodes.map((p) => p.package_id);

      // if no hotelbeds hotels selected, return avoiding this API call
      if (hotelBedsCodes.length == 0) {
        return
      }
      $scope.package_button_enable = false;
      $.get("/hotelbeds/check_hotels_prices", {
        check_in_date: $scope.my_trip_date.start_date,
        check_out_date: $scope.my_trip_date.end_date,
        trip_date_id: $scope.my_trip_date.id,
        packages: packages,
        adults: $scope.number_of_travellers,
        hotel_codes: hotelBedsCodes,
      }).done((hotelBedsPricing) => {
        setTimeout(() => {
          if (hotelBedsPricing.error) {
            if (hotelBedsPricing.message == 'HB/500 Error getting the information' && retry != true) {
              HotelBeds.updateHotelBedsHotelPrices($scope, true);
            } else {
              HotelBeds.startOverError($scope, hotelBedsPricing.message);
            }
          }
          const hotelsCodesWithPrices = hotelBedsPricing.map((p) => p.hotel);
          const updatedCustomerPackages =
            $scope.customer_packages.default.filter((customerPackage) => {
              if (
                !hotelsCodesWithPrices.includes(
                  parseInt(customerPackage.hotel_beds_hotel_code)
                )
              ) {
                return customerPackage; // If hotel code is not in hotelBedsPricing, return it without changes
              }

              const selectedHotel = hotelBedsPricing.find(
                (h) =>
                  h.hotel == parseInt(customerPackage.hotel_beds_hotel_code)
              );
              if (selectedHotel) {
                if (selectedHotel.rooms.length == 0) {
                  customerPackage.min_price = ''
                  customerPackage.max_price = ''
                  customerPackage.sold_out = true;
                }
                customerPackage.room_types.forEach(function(room_type) {
                  if (room_type.is_room_hb != true) {
                    return
                  }
                  selectedHotel.rooms.forEach(function(room_selected_hotel) {
                    if (room_selected_hotel.id == room_type.id && room_selected_hotel.package_id == room_type.package_id) {
                      if (room_type.description == null) { room_type.description = '' }
                      if (room_type.description.indexOf(room_selected_hotel.board_type) < 0) {
                        room_type.hotelbeds_board_codes = [room_selected_hotel.board_code]
                      }
                      room_type.min_price = room_selected_hotel.minPrice
                      room_type.max_price = room_selected_hotel.maxPrice
                      room_type.pricings = room_selected_hotel.pricing
                      if (customerPackage.min_price == null || parseFloat(customerPackage.min_price) > room_selected_hotel.minPrice) {
                        customerPackage.min_price = room_selected_hotel.minPrice
                      }
                      if (customerPackage.max_price == '' || parseFloat(customerPackage.max_price) < room_selected_hotel.maxPrice) {
                        customerPackage.max_price = room_selected_hotel.maxPrice
                      }
                    } else {
                      if(!selectedHotel.rooms.filter(function(room) { return room.id == room_type.id && room.package_id == room_type.package_id; }).length > 0) {
                        let room_types_no_price_found_hotelbeds = customerPackage.room_types.filter(function(room) { return room.id == room_type.id && room.package_id == room_type.package_id; })
                        room_types_no_price_found_hotelbeds.forEach(function(room_type_not_found_hotelbeds_each) {
                          customer_package_update = Object.values($scope.customer_packages)[0]
                          hotel_element = customer_package_update.filter(function(hotels_packages){ return (hotels_packages.hotel_id == room_type_not_found_hotelbeds_each.hotel_id) })
                          if (hotel_element.length > 0) {
                            hotel_element.forEach(function(hotel_element_single) {
                              found_room_type_hb = hotel_element_single.room_types.filter(function(room_type_on_package){ return (room_type_on_package.id == room_type_not_found_hotelbeds_each.id && room_type_on_package.package_id == room_type_not_found_hotelbeds_each.package_id)})
                              found_backup_room_type_cms = hotel_element_single.room_types_backup_group_inv.filter(function(room_type_bkp_package){ return (room_type_bkp_package.id == room_type_not_found_hotelbeds_each.id && room_type_bkp_package.package_id == room_type_not_found_hotelbeds_each.package_id)})
                              if ((found_backup_room_type_cms.length > 0) && (found_room_type_hb.length > 0)) {
                                $scope.customer_packages.default = customer_package_update.map(hotel => {
                                                                                                if (hotel.hotel_id === room_type_not_found_hotelbeds_each.hotel_id && hotel.package_id === found_backup_room_type_cms[0].package_id) {
                                                                                                  hotel.room_types = hotel.room_types.map(room_type_elem => {
                                                                                                    if (room_type_elem.id === parseInt(room_type_not_found_hotelbeds_each.id)) {
                                                                                                      if (customerPackage.min_price == null || parseFloat(customerPackage.min_price) > found_backup_room_type_cms[0].min_price) {
                                                                                                        customerPackage.min_price = found_backup_room_type_cms[0].min_price
                                                                                                      }
                                                                                                      if (customerPackage.max_price == '' || parseFloat(customerPackage.max_price) < found_backup_room_type_cms[0].max_price) {
                                                                                                        customerPackage.max_price = found_backup_room_type_cms[0].max_price
                                                                                                      }
                                                                                                      found_backup_room_type_cms[0].used = true
                                                                                                      return found_backup_room_type_cms[0]
                                                                                                    } else {
                                                                                                      return room_type_elem
                                                                                                    }
                                                                                                  })
                                                                                                }
                                                                                                return hotel
                                                                                              })
                              } else {
                                if (!room_types_no_price_found_hotelbeds[0].used) {
                                  room_types_no_price_found_hotelbeds[0].stock = 0;
                                }
                              }
                            });
                          }
                        })
                      }
                    }
                  });
                });
                customerPackage.loading = false;
              } else {
                customerPackage.sold_out = true;
              }
              if (customerPackage.room_types.filter(function(room_type){ return (room_type.stock > 0) }).length == 0) {
                customerPackage.sold_out = true;
              }
              $scope.package_button_enable = true;
              return customerPackage;
            });

          $scope.customer_packages.default = updatedCustomerPackages;
          $scope.$applyAsync();
        }, 0); // 0ms timeout to tests purposes only
      });    
  },

  retrieveHotelBedsCacheAvailability: ($scope) => {
    if ($scope.getStorage(HotelBeds.event_store_hb.HOTELBEDS_AVAILABILITY_RESPONSE)){
      $scope.hotel_beds_availability_return = $scope.getStorage(HotelBeds.event_store_hb.HOTELBEDS_AVAILABILITY_RESPONSE)
    }
    if ($scope.getStorage(HotelBeds.event_store_hb.HOTELBEDS_CUSTOMER_HOTEL_DATA_ON_RETURN)){
      $scope.customer_data_on_availability_call = $scope.getStorage(HotelBeds.event_store_hb.HOTELBEDS_CUSTOMER_HOTEL_DATA_ON_RETURN)
    }
  },

  updateHotelBedsAvailability: ($scope, customer_hotels_data, is_split_build, callback, retry) => {
    if ($scope.hotel_beds_availability_call_ongoing) {
      return
    }
    if ((Object.keys($scope.hotel_beds_availability_return).length !== 0) && JSON.stringify($scope.customer_data_on_availability_call) == JSON.stringify(customer_hotels_data)) {
      const hotelBedsAvailability = $scope.hotel_beds_availability_return;
      const newRooms = $scope.rooms;
      error_message = '';
      used_already = []
      newRoomsData = newRooms.map(room => {
        const hotelBedsRoomData = HotelBeds.findRoom(hotelBedsAvailability, room, used_already)
        if (hotelBedsRoomData != undefined) {
          used_already.push(hotelBedsRoomData)
          $scope.require_guest_names_on_pay_full = true;
          member_price = (hotelBedsRoomData.customer_room_price[room.members.length])
          room_price = (hotelBedsRoomData.customer_room_price[room.members.length] * room.members.length)
          member_in_room_price = member_price;
          let additional_fee_filtered = hotelBedsRoomData.additional_fees_for_hotel.find((fees_additional_fee) => fees_additional_fee[room.members.length] != undefined)[room.members.length]['additional_fees']
          room["additional_fees"] = additional_fee_filtered;
          room.additional_fees = structuredClone(additional_fee_filtered);
          additional_fee_filtered.forEach(function (additional_fees, index) {
            if (additional_fees["apply_type_per_person"]) {
              room.additional_fees[index]["amount"] = parseFloat(additional_fees["amount"]) * room.members.length
            }
          })

          total_additional_fees_room = room.additional_fees.reduce((partialSum, elem) => partialSum + parseFloat(elem["amount"]), 0);
          each_member_sum_fees = total_additional_fees_room / room.members.length
          if (is_split_build) {
            room.unit_price = room_price;
            room.amount = room_price;
            room.down_payment = 0;
          }
          room.rate_comment = hotelBedsRoomData.rate_comment
          room.is_room_hb = true;
          room.hotel_beds_ratekey = { 
                                      ratekey: hotelBedsRoomData.ratekey_hotel_beds,
                                      qty_rooms: hotelBedsRoomData.room_qty,
                                      qty_adults: hotelBedsRoomData.guest_qty,
                                      hb_net_price_room: hotelBedsRoomData.hb_net_price_room,
                                    }

          room.members.forEach(function (member) {
            member.unit_price = member_in_room_price;
            member.amount = member_in_room_price;
            member.down_payment = 0;
            member.total_hotel_fee = each_member_sum_fees,
            member.is_room_hotel_beds = true;
          })

          room.room_prices = []
          additional_fee_split_by_users = structuredClone(room.additional_fees);
          additional_fee_split_by_users.map((a) => a.amount = parseFloat(a.amount = a.amount / room.members.length));
          for (var i = 1; i <= room.room_pax; i++) {
            room.room_prices.push(
              {
                additional_fees: additional_fee_split_by_users,
                down_payment: 0,
                number_of_occupants: i,
                unit_price: member_in_room_price
              }
            ) 
          }
        } else {
          if (room["is_room_hb"] == true) {
            customer_package_update = Object.values($scope.customer_packages)[0]
            hotel_element = customer_package_update.filter(function(hotels_packages){ return (hotels_packages.hotel_id == room.hotel_id) })
            found_room_type_hb = hotel_element[0].room_types.filter(function(room_type_on_package){ return (room_type_on_package.id == room.room_type_id)})
            found_backup_room_type_cms = hotel_element[0].room_types_backup_group_inv.filter(function(room_type_bkp_package){ return (room_type_bkp_package.id == room.room_type_id)})
            if ((found_backup_room_type_cms.length > 0) && (found_room_type_hb.length > 0)) {
              $scope.customer_packages.default = customer_package_update.map(hotel => {
                                                                              if (hotel.hotel_id === room.hotel_id) {
                                                                                hotel.room_types = hotel.room_types.map(room_type_elem => {
                                                                                  if (room_type_elem.id === parseInt(room.room_type_id)) {
                                                                                    return found_backup_room_type_cms[0]
                                                                                  } else {
                                                                                    return room_type_elem
                                                                                  }
                                                                                })
                                                                              }
                                                                              return hotel
                                                                            })
              error_message = "Inventory changed for room ".concat(room.room_name).concat(" , please retry")
              $scope.backToPackageStep()
            } else {
              error_message = "Inventory not available for room ".concat(room.room_name).concat(" for ").concat(room.members.length).concat(" guests, please change allocation and retry")
            }
            $scope.hotel_beds_availability_return = {};
            $scope.setStorage(HotelBeds.event_store_hb.HOTELBEDS_AVAILABILITY_RESPONSE, $scope.hotel_beds_availability_return);
            $scope.setStorage(HotelBeds.event_store_hb.HOTELBEDS_CUSTOMER_HOTEL_DATA_ON_RETURN, {});
            $scope.setCurrentStep($scope.step_keys.package);
          }
        }

        return room;
      })
      if (error_message != '') {
        bootbox.alert(error_message);
      } else {
        $scope.rooms = newRooms;
        $scope.payment_data.grand_amount = 0;

        if (!is_split_build) {
          $scope.changeRoomOptions(is_confirm = true);
          $scope.rooms.forEach( room => { room['unit_price'] = 0 });
          $scope.saveRoomMembers(true);
          // save for confirmation
          $scope.confirm_pricings = $scope.hydrateScope();
          
          var event_additional_fees = angular.copy($scope.event_additional_fees);
          var payment_data = angular.copy($scope.payment_data);
          var rooms = angular.copy($scope.rooms);
          $.each(rooms, function (i, room) {
            room.amount = room.members.reduce((sum, member_hash) => sum + parseFloat(member_hash.amount), 0)
          })
          var addon_data = angular.copy($scope.addon_data);
          var flight_data = angular.copy($scope.flight_data);
          var event_discount_codes = angular.copy($scope.discount_code);
          $scope.confirm_pricings = {
            payment_data,
            rooms,
            addon_data,
            flight_data,
            event_additional_fees,
            event_discount_codes
          };
          $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
        }
      }
      if (callback && (error_message == '')) callback();
    } else {
      $scope.hotel_beds_availability_call_ongoing = true;
      $.get("/hotelbeds/check_availability_checkrate_hotel_beds", {
        check_in_date: $scope.my_trip_date.start_date,
        check_out_date: $scope.my_trip_date.end_date,
        trip_date_id: $scope.my_trip_date.id,
        room_data_hotelbeds: customer_hotels_data.default.room_type_data
      }).done((hotelBedsAvailability) => {
        setTimeout(() => {
          if (hotelBedsAvailability.error) {
            if (hotelBedsAvailability.message == 'HB/500 Error getting the information' && retry != true) {
              $scope.hotel_beds_availability_call_ongoing = false
              HotelBeds.updateHotelBedsAvailability($scope, customer_hotels_data, is_split_build, callback, true);
            } else {
              HotelBeds.startOverError($scope, hotelBedsAvailability.message);
            }
          }
          const newRooms = $scope.rooms;
          error_message = '';
          used_already = []
          newRoomsData = newRooms.map(room => {
            const hotelBedsRoomData = HotelBeds.findRoom(hotelBedsAvailability, room, used_already)
            if (hotelBedsRoomData != undefined) {
              used_already.push(hotelBedsRoomData)
              $scope.require_guest_names_on_pay_full = true;
              member_price = (hotelBedsRoomData.customer_room_price[room.members.length])
              room_price = (hotelBedsRoomData.customer_room_price[room.members.length] * room.members.length)
              member_in_room_price = member_price;
              let additional_fee_filtered = hotelBedsRoomData.additional_fees_for_hotel.find((fees_additional_fee) => fees_additional_fee[room.members.length] != undefined)[room.members.length]['additional_fees']
              room["additional_fees"] = additional_fee_filtered;
              room.additional_fees = structuredClone(additional_fee_filtered);
              additional_fee_filtered.forEach(function (additional_fees, index) {
                if (additional_fees["apply_type_per_person"]) {
                  room.additional_fees[index]["amount"] = parseFloat(additional_fees["amount"]) * room.members.length
                }
              })

              total_additional_fees_room = room.additional_fees.reduce((partialSum, elem) => partialSum + parseFloat(elem["amount"]), 0);
              each_member_sum_fees = total_additional_fees_room / room.members.length
              if (is_split_build) {
                room.unit_price = room_price;
                room.amount = room_price;
                room.down_payment = 0;
              }
              room.rate_comment = hotelBedsRoomData.rate_comment
              room.is_room_hb = true;
              room.hotel_beds_ratekey = { 
                                          ratekey: hotelBedsRoomData.ratekey_hotel_beds,
                                          qty_rooms: hotelBedsRoomData.room_qty,
                                          qty_adults: hotelBedsRoomData.guest_qty,
                                          hb_net_price_room: hotelBedsRoomData.hb_net_price_room,
                                        }

              room.members.forEach(function (member) {
                member.unit_price = member_in_room_price;
                member.amount = member_in_room_price;
                member.down_payment = 0;
                member.total_hotel_fee = each_member_sum_fees,
                member.is_room_hotel_beds = true;
              })

              room.room_prices = []
              additional_fee_split_by_users = structuredClone(room.additional_fees);
              additional_fee_split_by_users.map((a) => a.amount = parseFloat(a.amount = a.amount / room.members.length));
              for (var i = 1; i <= room.room_pax; i++) {
                room.room_prices.push(
                  {
                    additional_fees: additional_fee_split_by_users,
                    down_payment: 0,
                    number_of_occupants: i,
                    unit_price: member_in_room_price
                  }
                ) 
              }
            } else {
              if (room["is_room_hb"] == true) {
                customer_package_update = Object.values($scope.customer_packages)[0]
                hotel_element = customer_package_update.filter(function(hotels_packages){ return (hotels_packages.hotel_id == room.hotel_id) })
                found_room_type_hb = hotel_element[0].room_types.filter(function(room_type_on_package){ return (room_type_on_package.id == room.room_type_id)})
                found_backup_room_type_cms = hotel_element[0].room_types_backup_group_inv.filter(function(room_type_bkp_package){ return (room_type_bkp_package.id == room.room_type_id)})
                if ((found_backup_room_type_cms.length > 0) && (found_room_type_hb.length > 0)) {
                  $scope.customer_packages.default = customer_package_update.map(hotel => {
                                                                                  if (hotel.hotel_id === room.hotel_id) {
                                                                                    hotel.room_types = hotel.room_types.map(room_type_elem => {
                                                                                      if (room_type_elem.id === parseInt(room.room_type_id)) {
                                                                                        return found_backup_room_type_cms[0]
                                                                                      } else {
                                                                                        return room_type_elem
                                                                                      }
                                                                                    })
                                                                                  }
                                                                                  return hotel
                                                                                })
                  error_message = "Inventory changed for room ".concat(room.room_name).concat(" , please retry")
                  $scope.backToPackageStep()
                } else {
                  error_message = "Inventory not available for room ".concat(room.room_name).concat(" for ").concat(room.members.length).concat(" guests, please change allocation and retry")
                }
                $scope.hotel_beds_availability_return = {};
                $scope.hotel_beds_availability_call_ongoing = false;
                $scope.setStorage(HotelBeds.event_store_hb.HOTELBEDS_AVAILABILITY_RESPONSE, $scope.hotel_beds_availability_return);
                $scope.setStorage(HotelBeds.event_store_hb.HOTELBEDS_CUSTOMER_HOTEL_DATA_ON_RETURN, {});
                $scope.setCurrentStep($scope.step_keys.package);
              }
            }

            return room;
          })
          if (error_message != '') {
            bootbox.alert(error_message);
          } else {
            $scope.rooms = newRooms;

            $scope.payment_data.grand_amount = 0;
            if (!is_split_build) {
              $scope.changeRoomOptions(is_confirm = true);
              $scope.rooms.forEach( room => { room['unit_price'] = 0 });
              $scope.saveRoomMembers(true);
              // save for confirmation
              $scope.confirm_pricings = $scope.hydrateScope();
              
              var event_additional_fees = angular.copy($scope.event_additional_fees);
              var payment_data = angular.copy($scope.payment_data);
              var rooms = angular.copy($scope.rooms);
              $.each(rooms, function (i, room) {
                room.amount = room.members.reduce((sum, member_hash) => sum + parseFloat(member_hash.amount), 0)
              })
              var addon_data = angular.copy($scope.addon_data);
              var flight_data = angular.copy($scope.flight_data);
              var event_discount_codes = angular.copy($scope.discount_code);
              $scope.confirm_pricings = {
                payment_data,
                rooms,
                addon_data,
                flight_data,
                event_additional_fees,
                event_discount_codes
              };
              $scope.setStorage($scope.event_store.CONFIRM_PRICING, $scope.confirm_pricings);
            }
            // Saving to store returns and avoid call if they stay the same on next method call
            $scope.hotel_beds_availability_return = hotelBedsAvailability;
            $scope.customer_data_on_availability_call = structuredClone(customer_hotels_data);
            $scope.setStorage(HotelBeds.event_store_hb.HOTELBEDS_AVAILABILITY_RESPONSE, $scope.hotel_beds_availability_return);
            $scope.setStorage(HotelBeds.event_store_hb.HOTELBEDS_CUSTOMER_HOTEL_DATA_ON_RETURN, $scope.customer_data_on_availability_call);
            $scope.setStorage($scope.event_store.PASSWORD_RESET, true);
            $scope.hotel_beds_availability_call_ongoing = false;
            if ($scope.current_redirect_step_after_availability_return != 0) {
              $scope.setCurrentStep($scope.current_redirect_step_after_availability_return);
              $scope.setCurrentTrackedStep($scope.current_redirect_track_step_after_availability_return );
              $scope.current_redirect_step_after_availability_return = 0;
              $scope.current_redirect_track_step_after_availability_return = '';
            }
          }
          if (callback && (error_message == '')) callback();
        }, 0); // 0ms timeout to tests purposes only
      });
    }    
  },

  startOverError: ($scope, message) => {
    bootbox.alert(message);
    setTimeout(() => {
      $.post(
        $scope.event_booking_path + '/start_over',
        {},
        function (rs) {
          $scope.removeStorage();
          $scope.setCurrentStep($scope.step_keys.your_party);
          window.location.href = $scope.event_booking_path;
        }
      );
    }, 5000);
  },

  findRoom: (hotelBedsAvailability, room, used_already) => {
    return hotelBedsAvailability.find((h) => h.room_id == room.room_type_id && h.valid_price == true && room.members.length == h.guest_qty && used_already.indexOf(h) === -1)
  },

  existsRoomHbOnRoomTypeData: (room_type_data) => {
    let keys = Object.keys(room_type_data)
    let check_availability_hb = false
    keys.forEach(room_id => {
      if (room_type_data[room_id]['is_room_hb'] == true) {
        check_availability_hb = true
      }
    });
    return check_availability_hb
  }
}
window.HotelBeds = HotelBeds;
