window.commsPlatformAPI = {
  /**
   * Mass send SMS API
   * Method: POST
   * Route: /comms_platform/sms_blast
   * @param {object} options - The options
   * @param {string} options.s3_path - The file path of the CSV file
   * @param {string} options.event_name - The event name
   * @param {string} options.campaign - The campaign name
   * @param {string} options.content - The SMS content
   * @returns {Promise<string>} A promise that resolves to the UUID of the SMS blast result
   */
  massSendSMS({ s3_path, event_name, campaign, content }) {
    return window.ajaxUtils.post("/comms_platform/sms_blast", {
      data: {
        s3_path,
        event_name,
        campaign,
        content,
      }
    });
  },

  /**
   * Get SMS blast result API
   * Method: GET
   * Route: /comms_platform/sms_blast
   * @param {object} options - The options
   * @param {string} options.uuid - The UUID of the SMS blast result
   * @returns {Promise<{
   *   blast: {
   *     id: string,
   *     created_at: string,
   *     status: number,
   *     input_presigned_url: string,
   *     result_presigned_url: string,
   *     total_uploaded: number,
   *     total_submitted: number
   *   }[],
   *   total: number
   * }>} A promise that resolves to an object containing the list of blast results and total blasts:
   *  - blast: An array of blast results, each containing:
   *    - id (string): The UUID of the blast result
   *    - created_at (string): The timestamp of the blast result
   *    - status (number): 0 means WIP, 1 means Finished
   *    - input_presigned_url (string): The presigned URL of the CSV file
   *    - result_presigned_url (string): The presigned URL of the CSV file
   *    - total_uploaded (number): The total number of uploaded phone numbers
   *    - total_submitted (number): The total number of sent SMS
   *  - total (number): The total number of blast results
   */
  getSMSBlastResult({ uuid }) {
    return window.ajaxUtils.get(
      "/comms_platform/sms_blast",
      {
        data: {
          sms_blast_id: uuid,
        },
        global: false,
      },
      false
    );
  },

  /**
   * Get SMS blast result list API
   * Method: GET
   * Route: /comms_platform/sms_blast
   * @param {object} options - The options
   * @param {number} [options.offset = 0] - The starting offset for fetching blast results
   * @param {number} [options.limit = 10] - The maximum number of blast results to fetch
   * @param {boolean} [global = true] - Whether to show the global loading indicator
   * @returns {Promise<{
   *   blast: {
   *     id: string,
   *     created_at: string,
   *     status: number,
   *     input_presigned_url: string,
   *     result_presigned_url: string,
   *     total_uploaded: number,
   *     total_submitted: number
   *   }[],
   *   total: number
   * }>} A promise that resolves to an object containing the list of blast results and total blasts:
   *  - blast: An array of blast results, each containing:
   *    - id (string): The UUID of the blast result
   *    - created_at (string): The timestamp of the blast result
   *    - status (number): 0 means WIP, 1 means Finished
   *    - input_presigned_url (string): The presigned URL of the CSV file
   *    - result_presigned_url (string): The presigned URL of the CSV file
   *    - total_uploaded (number): The total number of uploaded phone numbers
   *    - total_submitted (number): The total number of sent SMS
   *  - total (number): The total number of blast results
   */
  getSMSBlastResultList({ offset = 0, limit = 10 }) {
    return window.ajaxUtils.get("/comms_platform/sms_blast", {
      data: {
        offset,
        limit,
      },
      global: false,
    });
  },

  /**
   * Abort an SMS blast API
   * Method: POST
   * Route: /comms_platform/sms_blast/abort/{uuid}
   * @param {object} options - The options
   * @param {string} options.uuid - The UUID of the SMS blast to abort
   * @returns {Promise<void>}
   */
  abortSMSBlast({ uuid }) {
    return window.ajaxUtils.post(`/comms_platform/sms_blast/abort/${uuid}`);
  },

  /**
   * Get AWS S3 upload credentials API
   * Method: GET
   * Route: /comms_platform/sms/s3_credentials
   * @returns {Promise<{
   *   region: string,
   *   bucketName: string,
   *   accessKeyId: string,
   *   secretAccessKey: string,
   *   sessionToken: string
   * }>} A promise that resolves to an object containing the AWS S3 upload credentials:
   * - region (string): The AWS region
   * - bucketName (string): The AWS S3 bucket name
   * - accessKeyId (string): The AWS access key ID
   * - secretAccessKey (string): The AWS secret access key
   * - sessionToken (string): The AWS session token
   */
  getAWSS3UploadCredentials() {
    return window.ajaxUtils.get("/comms_platform/s3_credentials", {
      global: false,
    });
  },
};

window.commsPlatformAWSUtils = $.extend({}, window.disputeAwsUtils, {
  async getUploadCredentials() {
    const res = await window.commsPlatformAPI.getAWSS3UploadCredentials();
    return res.data;
  },
});
