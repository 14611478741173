(function () {
  app.controller("OrderModifyRoomsCtrl", [
    "$scope",
    "$timeout",
    "$analytics",
    "$http",
    "$interval",
    "dragulaService",
    "$filter",
    function (
      $scope,
      $timeout,
      $analytics,
      $http,
      $interval,
      dragulaService,
      $filter
    ) {
      $scope.NAV_ROOMING = "Rooming";
      $scope.NAV_PAYMENTS = "Payments";
      $scope.FLOW_CONSOLIDATE = "Consolidate";
      $scope.FLOW_REMOVE_AND_REBOOK = "Remove & Rebook";
      $scope.FLOW_MODIFY_TRAVELERS = "Modify Travelers";
      $scope.TITLE_CONSOLIDATE = "Consolidate Rooms";
      $scope.TITLE_REMOVE_AND_REBOOK = "Remove and Rebook";
      $scope.TITLE_MODIFY_TRAVELERS = "Modify Travelers";

      $scope.event_currency = window.event_currency || "USD";
      $scope.navItems = [$scope.NAV_ROOMING, $scope.NAV_PAYMENTS];

      $scope.setDrawerInitialValues = () => {
        $scope.currentNav = $scope.navItems[0];
        $scope.hallway = [];
        $scope.reasonCode = {
          value: ''
        };
        $scope.fee = {
          value: 0,
        };
        $scope.discount = {
          value: 0,
        };
        $scope.addedRoomDetails = [];
        $scope.occupancy = 0;
        $scope.roomStockMap = {};
        $scope.memberMap = {};
        $scope.currentHotelProductID = {
          value: undefined,
        };
        $scope.leader = null;
      };

      $scope.init = async () => {
        await Promise.all([$scope.searchRoomDetails(), $scope.searchHotels()]);
        await $scope.searchRooms();

        switch ($scope.currentFlow) {
          case $scope.FLOW_CONSOLIDATE:
            $scope.drawerTitle = $scope.TITLE_CONSOLIDATE;
            $scope.calculateEachRoomMemberCost($scope.roomDetails);
            break;
          case $scope.FLOW_REMOVE_AND_REBOOK:
            $scope.drawerTitle = $scope.TITLE_REMOVE_AND_REBOOK;
            $scope.roomDetails.forEach((roomDetail) => {
              roomDetail.deleted = true;
              $scope.hallway.push(...roomDetail.members);
              $scope.roomStockMap[$scope.getRoomStockMapKey(roomDetail)] += 1;
            });
            break;
          case $scope.FLOW_MODIFY_TRAVELERS:
            $scope.drawerTitle = $scope.TITLE_MODIFY_TRAVELERS;
            $scope.calculateEachRoomMemberCost($scope.roomDetails);
            break;
          default:
            break;
        }

        $scope.$apply();
      };

      $scope.$on("order-consolidate-rooms", async () => {
        $scope.setDrawerInitialValues();
        $scope.currentFlow = $scope.FLOW_CONSOLIDATE;
        await $scope.init();
      });

      $scope.$on("order-remove-and-rebook-rooms", async () => {
        $scope.setDrawerInitialValues();
        $scope.currentFlow = $scope.FLOW_REMOVE_AND_REBOOK;
        await $scope.init();
      });

      $scope.$on("order-modify-travelers", async () => {
        $scope.setDrawerInitialValues();
        $scope.currentFlow = $scope.FLOW_MODIFY_TRAVELERS;
        await $scope.init();
      });

      $scope.$on("order-available-rooms", async () => {
        await $scope.searchRooms();
      });

      dragulaService.options($scope, "members", {
        invalid: function (el) {
          return el.classList.contains("dragula-invalid");
        },
        accepts: function (el, target, source, sibling) {
          return (
            target.classList.contains("dragula-hallway") ||
            Number(target.dataset.maxLength) >
              target.querySelectorAll(".dragula-valid").length
          );
        },
      });

      $scope.$on("members.drop-model", function (...args) {
        $scope.recalculatePayments();
      });

      $scope.recalculatePayments = () => {
        $scope.calculateEachRoomMemberCost($scope.roomDetails);
        $scope.calculateEachRoomMemberCost($scope.addedRoomDetails);
        $scope.calculateEachRoomMemberSharedFee();
        $scope.calculateEachRoomMemberSharedDiscount();
      };

      $scope.switchNav = (navItem) => {
        $scope.recalculatePayments();
        $scope.currentNav = navItem;
      };

      $scope.switchToRemoveAndRebookFlow = () => {
        $scope.modifyRoomsDrawer.close();
        $scope.modifyRoomsDrawer.$container.one("animationend", () => {
          $timeout(() => $scope.modifyRoomsDrawer.open('order-remove-and-rebook-rooms'), 100);
        });
      };

      $scope.deleteRoom = (roomDetail) => {
        $scope.roomStockMap[$scope.getRoomStockMapKey(roomDetail)] += 1;
        roomDetail.deleted = true;
        $scope.hallway.push(...roomDetail.members.splice(0));
        $timeout(() => $scope.$apply());
      };

      $scope.getArrayOfNumberLength = (number) => {
        return new Array(number).fill(undefined);
      };

      $scope.getMembersWrapperHeight = (maxOccupancy = 1) => {
        return `${Math.ceil(maxOccupancy / 2) * 40}px`;
      };

      $scope.showErrorModal = () => {
        $scope.modalTitle = "Error";
        $scope.modalMessage = `<strong>There are displaced guests in the hallway.</strong> Please assign rooms to these guest before resolving change order.`;
        $scope.cancelButtonText = "";
        $scope.confirmButtonText = "OK, got it";
        $("#order_modify_rooms_modal").modal("show");
      };

      $scope.showConfirmModal = () => {
        $scope.modalTitle = "Confirm";
        $scope.modalMessage = `<strong>Select apply change to finalize all room modifications,</strong> credits, fees, and payment information. Modification are final after this point and not refundable.`;
        $scope.cancelButtonText = "Go back";
        $scope.confirmButtonText = "Apply Change";
        $scope.refundOption = {
          value: "refund",
        };
        $scope.chargeOption = {
          value: "charge"
        }
        $("#order_modify_rooms_modal").modal("show");
      };

      $scope.showCancelOrderModal = () => {
        $("#order_cancel_order_modal").modal("show");
      };

      $scope.confirm = () => {
        // Show error message if Admin attempts to confirm change order when there are outstanding displaced guests in the 'hallway'
        if ($scope.hallway.length > 0) {
          $scope.showErrorModal();
          return;
        }

        // If ALL travelers are removed from the order, route the CTA to the existing order cancellation CTA.
        if (
          $scope.roomDetails.every((r) => !r.members?.length) &&
          (!$scope.addedRoomDetails.length || $scope.addedRoomDetails.every(r => !r.members?.length))
        ) {
          window.ajaxUtils.get(`/orders/cancel?id=${$scope.order.id}`);
          return;
        }

        $scope.recalculatePayments();
        $scope.showConfirmModal();
      };

      $scope.onModalCancel = () => {
        //
      };

      $scope.onModalConfirm = async () => {
        if ($scope.modalTitle !== "Confirm") return;

        await $scope.updateRooms();
        window.location.reload();
      };

      $scope.searchRoomDetails = async () => {
        $scope.roomDetails = await window.orderModifyApi.getOrderRoomMembers({
          order_number: $scope.order?.order_number,
        });
        $scope.roomDetails.forEach((room) => {
          room.unit_price_indexer = [0];
          room.pricings?.forEach(({ number_of_occupants, unit_price }) => {
            room.unit_price_indexer[Number(number_of_occupants)] =
              Number(unit_price);
          });
          room.room_price =
            room.members.length * room.unit_price_indexer[room.members.length];
        });
        $scope.roomMembers = $scope.roomDetails.reduce(
          (previousValue, currentValue) => {
            previousValue.push(...currentValue.members);
            return previousValue;
          },
          []
        );
        $scope.leader = $scope.roomMembers.find((m) => m.is_leader);
        $scope.memberMap = $scope.roomMembers.reduce(
          (previousValue, currentValue) => {
            const { unit_price = 0, total_amount = 0 } = currentValue || {};
            previousValue[$scope.getMemberKey(currentValue)] = {
              cost: total_amount,
              unit_price,
              total_amount,
            };
            if (
              currentValue.payer_name &&
              !previousValue[$scope.getPayerKey(currentValue)]
            ) {
              previousValue[$scope.getPayerKey(currentValue)] = {
                cost: total_amount,
                unit_price,
                total_amount,
              };
            }
            return previousValue;
          },
          {}
        );
        $scope.currentHotelProductID.value = `${$scope.roomDetails[0].product_id}`;
      };

      $scope.updateRooms = async () => {
        await window.orderModifyApi.updateOrderRooms({
          order_number: $scope.order?.order_number,
          current_rooms:
            $scope.currentFlow !== $scope.FLOW_REMOVE_AND_REBOOK
              ? $scope.roomDetails
              : undefined,
          added_rooms:
            $scope.currentFlow !== $scope.FLOW_CONSOLIDATE
              ? $scope.addedRoomDetails.filter(
                  (addedRoomDetail) => addedRoomDetail.members.length > 0
                )
              : undefined,
          discount_amount: $scope.discount.value || undefined,
          discount_reason_code: $scope.reasonCode.value || undefined,
          fee: $scope.fee.value || undefined,
          fee_reason_code: $scope.reasonCode.value || undefined,
          refund: $scope.refundOption.value === "refund",
          charge: $scope.chargeOption.value === "charge"
        });
      };

      $scope.searchHotels = async () => {
        $scope.hotels = (await window.orderModifyApi.getOrderHotels({
          order_number: $scope.order?.order_number,
        }) || []).map(hotel => ({
          ...hotel,
          product_name: hotel.visible ? hotel.product_name : `(Hidden) ${hotel.product_name}`,
        }));
        $scope.hotelsMap = $scope.hotels.reduce(
          (previousValue, currentValue) => {
            previousValue[currentValue.product_id] = currentValue.product_name;
            return previousValue;
          },
          {}
        );
      };

      $scope.getRoomStockMapKey = (room) => {
        return `${room.room_type_name}_${room.room_type_id}`;
      };

      $scope.searchRooms = async () => {
        $scope.rooms = await window.orderModifyApi.getOrderRooms({
          product_id: $scope.currentHotelProductID.value,
          order_number: $scope.order?.order_number,
          occupancy: $scope.occupancy,
        });
        $scope.rooms.forEach((room) => {
          room.min_occupants = Infinity;
          room.max_occupants = -Infinity;
          room.min_total_price = Infinity;
          room.max_total_price = -Infinity;
          room.total_price_indexer = [0];
          room.unit_price_indexer = [0];

          // Set occupants range & total price range
          room.pricings?.forEach(({ number_of_occupants, unit_price }) => {
            const totalPrice = number_of_occupants * Number(unit_price);
            room.min_occupants = Math.min(
              room.min_occupants,
              number_of_occupants
            );
            room.max_occupants = Math.max(
              room.max_occupants,
              number_of_occupants
            );
            room.min_total_price = Math.min(room.min_total_price, totalPrice);
            room.max_total_price = Math.max(room.max_total_price, totalPrice);
            room.unit_price_indexer[number_of_occupants] = Number(unit_price);
            room.total_price_indexer[number_of_occupants] = totalPrice;
          });

          // Store room stock in map for future calculation
          const ROOM_STOCK_MAP_KEY = $scope.getRoomStockMapKey(room);
          if (!$scope.roomStockMap.hasOwnProperty(ROOM_STOCK_MAP_KEY)) {
            $scope.roomStockMap[ROOM_STOCK_MAP_KEY] = room.stock;
          }
        });
        $scope.$apply();
      };

      $scope.addOccupancy = () => {
        $scope.occupancy += 1;
      };

      $scope.deductOccupancy = () => {
        if ($scope.occupancy <= 0) {
          return;
        }
        $scope.occupancy -= 1;
      };

      $scope.addRoom = (room) => {
        $scope.roomStockMap[$scope.getRoomStockMapKey(room)] -= 1;

        const copiedRoom = $.extend(true, {}, room);

        copiedRoom.members = [];
        copiedRoom.track_id = Date.now();
        copiedRoom.product_id = $scope.currentHotelProductID.value;

        $scope.addedRoomDetails.push(copiedRoom);
      };

      $scope.deleteAddedRoom = (room, roomIndex) => {
        $scope.roomStockMap[$scope.getRoomStockMapKey(room)] += 1;
        $scope.hallway.push(...room.members);
        $scope.addedRoomDetails.splice(roomIndex, 1);
      };

      $scope.getRemovals = () => {
        return (
          $scope.roomDetails
            ?.filter((r) => r.deleted)
            .reduce((previousValue, currentValue) => {
              previousValue += currentValue.room_price;
              return previousValue;
            }, 0) || 0
        );
      };

      $scope.getAdditions = () => {
        if (
          !$scope.addedRoomDetails?.length ||
          $scope.currentFlow === $scope.FLOW_CONSOLIDATE
        ) {
          return 0;
        }

        const addedRoomPrice = $scope.addedRoomDetails.reduce((previousValue, currentValue) => {
          previousValue +=
            currentValue.total_price_indexer[currentValue.members.length];
          return previousValue;
        }, 0);
        const addedMemberTicketPrice = $scope.roomMembers.filter(m => m.newlyAdded).reduce((accu, curr) => {
          accu += curr.total_amount || 0;
          return accu;
        }, 0);
        return addedRoomPrice + addedMemberTicketPrice;
      };

      $scope.getMemberKey = (roomMember) => {
        return roomMember.room_member_id
      };

      $scope.getPayerKey = (roomMember) => {
        return roomMember.payer_room_member_id
      };

      $scope.calculateEachRoomMemberCost = (roomDetails) => {
        roomDetails.forEach((roomDetail) => {
          if (!roomDetail.members?.length) {
            return;
          }

          roomDetail.unit_price =
            roomDetail.unit_price_indexer[roomDetail.members.length] ||
            roomDetail.unit_price;

          // Set each member cost
          roomDetail.members.forEach((member) => {
            const memberKey = $scope.getMemberKey(member);
            const {
              unit_price: originalRoomUnitPrice = 0,
              total_amount: totalAmount = 0,
            } = $scope.memberMap[memberKey];
            const currentRoomUnitPrice = Number(roomDetail.unit_price);

            $scope.memberMap[memberKey].cost =
              currentRoomUnitPrice -
              Number(originalRoomUnitPrice) +
              Number(totalAmount);
          });
        });

        // Transfer member cost to payer
        $scope.roomMembers.forEach((member) => {
          if (!member.payer_name) return;

          const payerKey = $scope.getPayerKey(member);
          const memberKey = $scope.getMemberKey(member);

          $scope.memberMap[payerKey].cost +=
            $scope.memberMap[memberKey]?.cost || 0;
          $scope.memberMap[memberKey].cost = 0;
        });
      };

      $scope.calculateEachRoomMemberSharedFee = () => {
        const sharedFee = Number(
          ($scope.fee.value / ($scope.roomMembers.length || 1) || 0).toFixed(2)
        );

        // Set each member fee
        $scope.roomMembers.forEach((roomMember) => {
          const memberKey = $scope.getMemberKey(roomMember);
          $scope.memberMap[memberKey].fee = sharedFee;
        });

        // Transfer member fee to payer
        $scope.roomMembers.forEach((roomMember) => {
          if (!roomMember.payer_name) return;
          const payerKey = $scope.getPayerKey(roomMember);
          const memberKey = $scope.getMemberKey(roomMember);
          $scope.memberMap[payerKey].fee +=
            $scope.memberMap[memberKey]?.fee || 0;
          $scope.memberMap[memberKey].fee = 0;
        });
      };

      $scope.calculateEachRoomMemberSharedDiscount = () => {
        const sharedDiscount = Number(
          (
            $scope.discount.value / ($scope.roomMembers.length || 1) || 0
          ).toFixed(2)
        );

        // Set each member discount
        $scope.roomMembers.forEach((roomMember) => {
          const memberKey = $scope.getMemberKey(roomMember);
          $scope.memberMap[memberKey].discount = sharedDiscount;
        });

        // Transfer member discount to payer
        $scope.roomMembers.forEach((roomMember) => {
          if (!roomMember.payer_name) return;
          const payerKey = $scope.getPayerKey(roomMember);
          const memberKey = $scope.getMemberKey(roomMember);
          $scope.memberMap[payerKey].discount +=
            $scope.memberMap[memberKey]?.discount || 0;
          $scope.memberMap[memberKey].discount = 0;
        });
      };

      $scope.getTotalCostDiff = (roomMember) => {
        const { fee = 0, discount = 0 } =
          $scope.memberMap[$scope.getMemberKey(roomMember)] || {};
        return fee - discount;
      };

      $scope.getTotalCostCount = (roomMember) => {
        const {
          cost = 0,
          fee = 0,
          discount = 0,
        } = $scope.memberMap[$scope.getMemberKey(roomMember)] || {};
        return cost + fee - discount;
      };

      $scope.getNewPaymentCount = (roomMember) => {
        const totalCost = $scope.getTotalCostCount(roomMember);
        const paidToDate = Number(roomMember.paid_to_date);
        const leftInstallments = roomMember.left_installments || 1;
        if (totalCost === 0) {
          return 0;
        }
        return (totalCost - paidToDate) / leftInstallments;
      };
    },
  ]);
}.call(this));
