(function () {
  app.controller("CustomPaymentPlanCtrl", [
    "$scope",
    "$timeout",
    "$http",
    function ($scope, $timeout, $http) {

      $scope.togglePackageIds = function (package_id, toggle) {

        var params = {
          package_id: package_id,
          toggle: toggle,
          custom_payment_plan_id: $scope.custom_payment_plan_id
        };
        $.post($scope.update_package_ids_event_custom_payment_plan, params, function (rs) {
          $timeout(function () {});
        });
      };

      $scope.onReady = function () {
      };
    },
  ]);
}.call(this));
